import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"

import BottomBanner from "../components/BottomBanner"
import ContactTopBanner from "../components/ContactTopBanner"
import PageHeader from "../components/PageHeader"
import BusinessContact from "../components/BusinessContact"
import TextElement from "../components/TextElement"

export default function Imprint({data}){
    const contact = data.allContactJson.nodes[0]
    const imprint = data.allImprintJson.nodes[0]

    return(
      <div>
        <Helmet>
          <meta charSet="utf-8"/>
          <title>Impressum</title>
          <html lang="de"/>
        </Helmet>
        <Layout>
            <ContactTopBanner/>
            <PageHeader>Impressum</PageHeader>
            <BusinessContact data={contact.FirmaKontakt}/>
            <TextElement data={imprint} centered={true}/>
            <BottomBanner/>
        </Layout>
      </div>
    )
}

export const query = graphql`
{
  allImprintJson{
    nodes{
      TextSections{
          title
          text
      }
    }
  }
  allContactJson{
    nodes{
      title
      FirmaKontakt {
          name
          name2
          telefonnummer
          email
          Adresse {
              Street
              Ort
              Land
          }
        }
      }     
    }
}`