import React from "react"
import styled from "styled-components"
import Mail from "../../data/icons/mail.svg"
import Phone from "../../data/icons/phone.svg"

const Container = styled.div`
margin-top:30px;
@media(min-width:601px){
    margin-top:0;
}

& h3 {
    text-align:center;
    margin:0;
    font-size:12vw;

    @media(min-width:401px){
        font-size:36px;
    }
    @media(min-width:601px){
        font-size:48px;
    }
    font-family:Hind Guntur;
    font-style:normal;
    font-weight:bold;
    line-height:25px;
    -ms-grid-column:1;
    -ms-grid-column-span:12;
    grid-column:1/13;
}

& >p{
    text-align:center;
    margin:0;
    font-size:6vw;

    @media(min-width:401px){
        font-size:20px;
    }
    @media(min-width:601px){
        font-size: 26px;
    }
    font-family:Hind Guntur;
    font-style:normal;
    font-weight:300;
    -ms-grid-column: 1;
    -ms-grid-column-span: 12;
    grid-column: 1/13;
}
`

const TextContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
    -ms-flex-direction:column;
        flex-direction:column;
-webkit-box-pack:center;
    -ms-flex-pack:center;
        justify-content:center;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;

margin-top:20px;
`

const Text = styled.div`
margin:0;

display:-webkit-box;

display:-ms-flexbox;

display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;

& p{
    margin-left: 15px;
	line-height: 16px;
    font-weight: bold;
    font-size:1.3em;
}

& svg{
    height: 50px;
    width: 50px;
}

@media(min-width:601px){
    -webkit-box-pack:left;
        -ms-flex-pack:left;
            justify-content:left;
}
`

const AdressBlock = styled.div`
margin-top:20px;
& p{
    margin-top: 0.4em;
    line-height:35px;
}
`

const ContentContainer = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
    -ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-align:center;
    -ms-flex-align:center;
        align-items:center;
-webkit-box-pack:space-evenly;
    -ms-flex-pack:space-evenly;
        justify-content:space-evenly;

margin-top: 20px;
margin-bottom: 50px;
text-align:center;
`

export default function BusinessContact(props){
    return(
        <Container className="row">
            <h3>{props.data.name}</h3>
            <p>{props.data.name2}</p>
            <ContentContainer className="l3-10 m2-11 s1-12">
                <TextContainer>
                    <Text>
                        <Phone/>
                        <p>{props.data.telefonnummer}</p>
                    </Text>
                    <Text>
                        <Mail/>
                        <p>{props.data.email}</p>
                    </Text>
                </TextContainer>
                <AdressBlock>
                    <p>
                        { props.data.Adresse.Street} <br/>
                        { props.data.Adresse.Ort} <br/>
                        { props.data.Adresse.Land} <br/>
                    </p>
                </AdressBlock>
            </ContentContainer>
        </Container>
    )
}